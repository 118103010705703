<template>
  <div class="ma-0 row-bg-color custom-content-height">
    <div class="bg-white rounded-lg h-100 py-8 pl-0 px-sm-6 scroll-y">
      <datatable-detail
        class="col-9 col-md-9 col-sm-9"
        :table_properties="table_properties"
      />
      <div class="my-10 ml-4" :class="{ 'text-right pr-4': $vuetify.rtl }">
        <h3 class="font-size-h1 text-dark-65">
          {{ $t("overview") }}
        </h3>
        <p class="text-dark-65 font-size-h4 w-1000px mt-3">
          {{
            $t(
              "our_return_plugin_can_be_easily_integrated_with_any_application"
            )
          }}
        </p>
        <div class="row mt-8">
          <div class="col-lg-8">
            <!-- Authorization token -->
            <h3 class="font-size-h1 text-dark-65 mt-2">
              {{ $t("authorization_token") }}
            </h3>
            <p class="mt-8">
              {{ $t("you_can_get_the") }}
              <code>Authorization Token</code>
              {{ $t("from_customer_service") }}
            </p>
            <!-- create -->
            <h3 class="font-size-h1 text-dark-65 mt-10">
              {{ $t("create_return") }}
            </h3>
            <p class="mt-6 mb-6 text-dark-65">
              {{ $t("integrate_with_the_following_endpoint") }}
            </p>
            <h5 class="font-size-h3 text-dark-65 mt-10 mb-4">
              {{ $t("request_endpoint") }}
            </h5>
            <!-- request method -->
            {{ $t("the_request_method") }}:<code>POST</code>
            <p class="mt-6 mb-6 text-dark-65">
              {{ $t("the_request_end_point") }}
              <code>{url}/api/v3/returns/create</code>
            </p>
            <!-- request header -->
            <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
              {{ $t("request_header") }}
            </h5>
            <div class="highlight mb-5">
              <div class="highlight-code">
                <span class="token-tag">Authorization: </span>
                <span class="token-value">"Bearer [TOKEN]",</span>
                <br />
                <span class="token-tag">Content-type: </span>
                <span class="token-value"
                  >"application/json; charset=utf-8",</span
                >
                <br />
                <span class="token-tag">Accept: </span>
                <span class="token-value">"application/json</span>
                <br />
              </div>
            </div>
            <!-- body -->
            <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
              {{ $t("request_body") }}
            </h5>
            <div class="highlight">
              <button
                class="btn"
                :class="{
                  'highlight-copy-rtl': $vuetify.rtl,
                  'highlight-copy': !$vuetify.rtl,
                }"
                @click="copyCreateReturnBody"
              >
                {{ copyCreateReturnBodyButtonText }}
              </button>
              <div class="highlight-code">
                <span class="token-string">{</span>
                <div class="ml-4">
                  <span class="token-tag">"client_reference": </span>
                  <span class="token-value">"REFERENCE_TOKEN",</span>
                  <br />
                  <span class="token-tag">"original_awb": </span>
                  <span class="token-value">"ORIGINAL_AWB",</span>
                  <br />
                  <span class="token-tag">"expected_collection_date": </span>
                  <span class="token-value">"DATE_TIME",</span>
                  <br />
                  <span class="token-tag">"warehouse": </span>
                  <span class="token-value">"WAREHOUSE_ID",</span>
                  <br />
                  <span class="token-tag">"collection_address": </span>
                  <span class="token-value">{</span>
                  <br />
                  <div class="ml-4">
                    <span class="token-tag">"name": </span>
                    <span class="token-value">"VALUE",</span>
                    <br />
                    <span class="token-tag">"phone": </span>
                    <span class="token-value">"VALUE",</span>
                    <br />
                    <span class="token-tag">"state": </span>
                    <span class="token-value">"VALUE",</span>
                    <br />
                    <span class="token-tag">"city": </span>
                    <span class="token-value">"VALUE",</span>
                    <br />
                    <span class="token-tag">"area": </span>
                    <span class="token-value">"VALUE",</span>
                    <br />
                    <span class="token-tag">"address": </span>
                    <span class="token-value">"VALUE",</span>
                    <br />
                    <span class="token-tag">"address_2": </span>
                    <span class="token-value">"VALUE",</span>
                    <br />
                  </div>
                  <span class="token-string">}</span>
                  <br />
                  <span class="token-tag">"items": </span>
                  <span class="token-string">[</span>
                  <br />
                  <div class="ml-4">
                    <span class="token-string">{</span>
                    <div class="ml-4">
                      <span class="token-tag">"category": </span>
                      <span class="token-value">"VALUE",</span>
                      <br />
                      <span class="token-tag">"length": </span>
                      <span class="token-value">"VALUE",</span>
                      <br />
                      <span class="token-tag">"width": </span>
                      <span class="token-value">"VALUE",</span>
                      <br />
                      <span class="token-tag">"height": </span>
                      <span class="token-value">"VALUE",</span>
                      <br />
                      <span class="token-tag">"price": </span>
                      <span class="token-value">"VALUE",</span>
                      <br />
                      <span class="token-tag">"weight": </span>
                      <span class="token-value">"VALUE",</span>
                      <br />
                      <span class="token-tag">"description": </span>
                      <span class="token-value">"VALUE"</span>
                    </div>
                    <br />
                    <span class="token-string">}</span>
                  </div>
                  <span class="token-string">]</span>
                </div>
                <span class="token-string">}</span>
              </div>
            </div>
            <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
              {{ $t("expected_success_response") }}
            </h5>
            <div class="highlight">
              <div class="highlight-code">
                <span class="token-string">{</span>
                <div class="ml-4">
                  <span class="token-tag">"success": </span>
                  <span class="token-value">true,</span>
                  <br />
                  <span class="token-tag">"message": </span>
                  <span class="token-value">"Successfully created.",</span>
                  <br />
                  <span class="token-tag">"client_reference": </span>
                  <span class="token-value">"CLIENT_REF",</span>
                  <br />
                </div>
                <span class="token-string">}</span>
              </div>
            </div>
            <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
              {{ $t("expected_failed_validation_response") }}
            </h5>

            <div class="highlight">
              <div class="highlight-code">
                <span class="token-string">{</span>
                <div class="ml-4">
                  <span class="token-tag">success: </span>
                  <span class="token-value">false</span>
                  <br />
                  <span class="token-tag">code: </span>
                  <span class="token-value">400</span>
                  <br />
                  <span class="token-tag">message: </span>
                  <span class="token-value">{</span>
                  <br />
                  <div class="ml-4">
                    <span class="token-tag">customer: </span>
                    <span class="token-value">[</span>
                    <br />
                    <div class="ml-4">
                      <span class="token-value"
                        >"The selected Customer is invalid."</span
                      >
                      <br />
                    </div>
                    <span class="token-string">],</span>
                    <br />
                    <span class="token-tag">client_reference: </span>
                    <span class="token-value">[</span>
                    <br />
                    <div class="ml-4">
                      <span class="token-value"
                        >"The selected Client reference is invalid."</span
                      >
                      <br />
                    </div>
                    <span class="token-string">],</span>
                  </div>
                  <span class="token-string">}</span>
                </div>
                <span class="token-string">}</span>
              </div>
            </div>

            <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
              {{ $t("exception_response") }}
            </h5>

            <div class="highlight">
              <div class="highlight-code">
                <span class="token-string">{</span>
                <div class="ml-4">
                  <span class="token-tag">success: </span>
                  <span class="token-value">false,</span>
                  <br />
                  <span class="token-tag">code: </span>
                  <span class="token-value">500,</span>
                  <br />
                  <span class="token-tag">message: </span>
                  <span class="token-value">"Failed to create"</span>
                  <br />
                </div>
                <span class="token-string">}</span>
              </div>
            </div>

            <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
              {{ $t("api_reference") }}
            </h5>
            <div class="table-responsive border rounded mt-8">
              <table class="table table-striped align-top mb-0 g-5">
                <thead>
                  <tr class="fs-4 fw-bold text-dark p-6">
                    <th class="min-w-250px">
                      {{ $t("name") }}
                    </th>
                    <th class="min-w-100px">
                      {{ $t("type") }}
                    </th>
                    <th class="min-w-100px">
                      {{ $t("parameter") }}
                    </th>
                    <th class="min-w-500px">
                      {{ $t("description") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- client ref -->
                  <tr>
                    <td>
                      <code>client_reference</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>
                      {{ $t("clients_reference_for_the_return") }}
                    </td>
                  </tr>
                  <!-- original awb -->
                  <tr>
                    <td>
                      <code>original_awb</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">optional</code>
                    </td>
                    <td>{{ $t("orders_original_awb") }}</td>
                  </tr>
                  <!-- original client ref -->
                  <tr>
                    <td>
                      <code>original_client_reference</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>
                      {{ $t("orders_original_client_reference") }}
                    </td>
                  </tr>
                  <!-- collection date -->
                  <tr>
                    <td>
                      <code>expected_collection_date</code>
                    </td>
                    <td><code>datetime</code></td>
                    <td>
                      <code class="ms-0">optional</code>
                    </td>
                    <td>
                      {{
                        $t(
                          "expected_collection_date_time_for_the_return_format"
                        )
                      }}
                      <code>YYYY-MM-DD HH:MM:SS</code>
                      <br />
                      {{ $t("example") }}:
                      <code>2012-12-12 12:12:12</code>
                    </td>
                  </tr>
                  <!-- warehouse -->
                  <tr>
                    <td>
                      <code>warehouse</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">optional</code>
                    </td>
                    <td>{{ $t("destination_warehouse") }}</td>
                  </tr>
                  <!-- name -->
                  <tr>
                    <td>
                      <code>collection_address.name</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>{{ $t("name_of_the_sender") }}</td>
                  </tr>
                  <!-- phone -->
                  <tr>
                    <td>
                      <code>collection_address.phone</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>
                      {{ $t("phone_number_of_the_sender") }}
                    </td>
                  </tr>
                  <!-- state -->
                  <tr>
                    <td>
                      <code>collection_address.state</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>
                      {{
                        $t(
                          "location_of_the_sender_i_e_the_state_or_province_in_which_they_reside"
                        )
                      }}
                    </td>
                  </tr>
                  <!-- city -->
                  <tr>
                    <td>
                      <code>collection_address.city</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>{{ $t("city_of_the_sender") }}</td>
                  </tr>
                  <!-- area -->
                  <tr>
                    <td>
                      <code>collection_address.area</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">optional</code>
                    </td>
                    <td>{{ $t("area_of_the_sender") }}</td>
                  </tr>
                  <!-- address -->
                  <tr>
                    <td>
                      <code>collection_address.address</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>{{ $t("address_of_the_sender") }}</td>
                  </tr>
                  <!-- address_2 -->
                  <tr>
                    <td>
                      <code>collection_address.address_2</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>
                      {{ $t("additional_address_of_the_sender") }}
                    </td>
                  </tr>
                  <!-- category -->
                  <tr>
                    <td>
                      <code>item.category</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>
                      {{ $t("senders_additional_address") }}
                      <br />
                      <br />
                      {{ $t("available_options") }}
                      <code>Food</code>, <code>Clothes</code>,
                      <code>Medical Equipments</code>,
                      <code>Electrical Appliances</code>,
                      <code>Electronic Devices</code>, <code>Medicines</code>,
                      <code>Toys</code>, <code>Paper & Documents</code>,
                      <code>Other</code>
                    </td>
                  </tr>
                  <!-- length -->
                  <tr>
                    <td>
                      <code>items.length</code>
                    </td>
                    <td><code>number</code></td>
                    <td>
                      <code class="ms-0">optional</code>
                    </td>
                    <td>{{ $t("item_length") }}</td>
                  </tr>
                  <!-- width -->
                  <tr>
                    <td>
                      <code>items.width</code>
                    </td>
                    <td><code>number</code></td>
                    <td>
                      <code class="ms-0">optional</code>
                    </td>
                    <td>{{ $t("item_width") }}</td>
                  </tr>
                  <!-- height -->
                  <tr>
                    <td>
                      <code>items.height</code>
                    </td>
                    <td><code>number</code></td>
                    <td>
                      <code class="ms-0">optional</code>
                    </td>
                    <td>{{ $t("item_height") }}</td>
                  </tr>
                  <!-- price -->
                  <tr>
                    <td>
                      <code>items.price</code>
                    </td>
                    <td><code>number</code></td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>{{ $t("item_price") }}</td>
                  </tr>
                  <!-- weight -->
                  <tr>
                    <td>
                      <code>items.weight</code>
                    </td>
                    <td><code>number</code></td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>{{ $t("item_weight") }}</td>
                  </tr>
                  <!-- description -->
                  <tr>
                    <td>
                      <code>items.description</code>
                    </td>
                    <td><code>string</code></td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>{{ $t("item_description") }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Get Location -->
            <h3 class="font-size-h1 text-dark-65 mt-20">
              {{ $t("get_location") }}
            </h3>
            <h5 class="font-size-h3 text-dark-65 mt-6 mb-4">
              {{ $t("request_endpoint") }}
            </h5>
            <!-- request type -->
            {{ $t("the_request_method") }}:<code>GET</code>
            <p class="mt-6 mb-6 text-dark-65">
              {{ $t("the_request_end_point") }}:<code
                >/api/v3/returns/locations/{country_code}</code
              >
            </p>
            <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
              {{ $t("request_header") }}
            </h5>
            <div class="highlight mb-5">
              <div class="highlight-code">
                <span class="token-tag">Authorization: </span>
                <span class="token-value">"Bearer [TOKEN]",</span>
                <br />
                <span class="token-tag">Content-type: </span>
                <span class="token-value"
                  >"application/json; charset=utf-8",</span
                >
                <br />
                <span class="token-tag">Accept: </span>
                <span class="token-value">"application/json</span>
                <br />
              </div>
            </div>
            <!-- <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">Request body</h5>
            <div class="highlight mb-5">
              <button class="highlight-copy btn" @click="copyGetLocationBody">
                {{ copyGetLocationBodyButtonText }}
              </button>
              <div class="highlight-code">
                <span class="token-string">{</span>
                <div class="ml-4">
                  <span class="token-tag">"country_code": </span>
                  <span class="token-value">"KSA",</span>
                  <br />
                </div>
                <span class="token-string">}</span>
              </div>
            </div> -->
            <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
              {{ $t("expected_success_response") }}
            </h5>
            <div class="highlight mb-5">
              <div class="highlight-code">
                <span class="token-string">{</span>
                <div class="ml-4">
                  <span class="token-tag">"success": </span>
                  <span class="token-value">true,</span>
                  <br />
                  <span class="token-tag">"data": </span>
                  <span class="token-string">{</span>
                  <br />
                  <div class="ml-4">
                    <span class="token-tag">"country_id": </span>
                    <span class="token-value">191</span>
                    <br />
                    <span class="token-tag">"country_name": </span>
                    <span class="token-value">"Saudi Arabia",</span>
                    <br />
                    <span class="token-tag">"country_code": </span>
                    <span class="token-value">"KSA",</span>
                    <br />
                    <span class="token-tag">"country_shortCode": </span>
                    <span class="token-value">"SA",</span>
                    <br />
                    <span class="token-tag">"states": </span>
                    <span class="token-string">[</span>
                    <div class="ml-4">
                      <div class="ml-4">
                        <span class="token-string">{ </span>
                        <br />
                        <div class="ml-4">
                          <span class="token-tag">"stated_id": </span>
                          <span class="token-value">4341</span>
                          <br />
                          <span class="token-tag">"state_name": </span>
                          <span class="token-value">Asir Principality</span>
                          <br />
                          <span class="token-tag">"cities": </span>
                          <span class="token-string">[ </span>
                          <br />
                          <div class="ml-4">
                            <span class="token-string">{ </span>
                            <br />
                            <div class="ml-4">
                              <span class="token-tag">"city_id": </span>
                              <span class="token-value">50443</span>
                              <br />
                              <span class="token-tag">"city_name": </span>
                              <span class="token-value">"Abha"</span>
                              <br />
                              <span class="token-tag">"areas": </span>
                              <span class="token-string">[</span>
                              <div class="ml-4">
                                <span class="token-string">{ </span>
                                <br />
                                <div class="ml-4">
                                  <span class="token-tag">"area_id": </span>
                                  <span class="token-value">45,</span>
                                  <br />
                                  <span class="token-tag">"area_name": </span>
                                  <span class="token-value">"Ad Dilam",</span>
                                  <br />
                                </div>
                                <span class="token-string">} </span>
                                <br />
                              </div>
                              <span class="token-string">] </span>
                              <br />
                            </div>

                            <span class="token-string">} </span>
                          </div>
                          <span class="token-string">] </span>
                        </div>
                        <span class="token-string">} </span>
                        <br />
                      </div>
                    </div>
                  </div>
                  <span class="token-string">] </span>
                  <br />
                </div>
                <span class="token-string">}</span>
              </div>
            </div>

            <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
              {{ $t("expected_failed_validation_response") }}
            </h5>

            <div class="highlight">
              <div class="highlight-code">
                <span class="token-string">{</span>
                <div class="ml-4">
                  <span class="token-tag">success: </span>
                  <span class="token-value">false,</span>
                  <br />
                  <span class="token-tag">code: </span>
                  <span class="token-value">422,</span>
                  <br />
                  <span class="token-tag">errors: </span>
                  <span class="token-value">{</span>
                  <br />
                  <div class="ml-4">
                    <span class="token-tag">country_code: </span>
                    <span class="token-string">[</span>
                    <br />
                    <div class="ml-4">
                      <span class="token-value"
                        >"The selected country code is invalid."</span
                      >
                      <br />
                    </div>
                    <span class="token-string">],</span>
                    <br />
                  </div>
                  <span class="token-string">}</span>
                </div>
                <span class="token-string">}</span>
              </div>
            </div>

            <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
              {{ $t("exception_response") }}
            </h5>

            <div class="highlight">
              <div class="highlight-code">
                <span class="token-string">{</span>
                <div class="ml-4">
                  <span class="token-tag">success: </span>
                  <span class="token-value">false,</span>
                  <br />
                  <span class="token-tag">code: </span>
                  <span class="token-value">500,</span>
                  <br />
                  <span class="token-tag">message: </span>
                  <span class="token-value">"Failed to get locations"</span>
                  <br />
                </div>
                <span class="token-string">}</span>
              </div>
            </div>

            <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
              {{ $t("api_reference") }}
            </h5>
            <div class="table-responsive border rounded mt-6">
              <table class="table table-striped align-top mb-0 g-5">
                <thead>
                  <tr class="fs-4 fw-bold text-dark p-6">
                    <th class="min-w-250px">
                      {{ $t("name") }}
                    </th>
                    <th class="min-w-250px">
                      {{ $t("type") }}
                    </th>
                    <th class="min-w-100px">
                      {{ $t("parameter") }}
                    </th>
                    <th class="min-w-500px">
                      {{ $t("description") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- client ref -->
                  <tr>
                    <td>
                      <code>country_code</code>
                    </td>
                    <td>
                      <code>string</code>
                    </td>
                    <td>
                      <code class="ms-0">mandatory</code>
                    </td>
                    <td>
                      {{ $t("the_country_code") }} <br />
                      {{ $t("minimum_length_is") }}
                      <code>2</code> & <br />

                      {{ $t("maximum_length_is") }}
                      <code>3</code>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatatableDetail from "@/own/components/datatable/DatatableDetail";

export default {
  name: "ReturnClientAPIDoc",
  components: {
    DatatableDetail,
  },
  data: function () {
    return {
      table_properties: {
        title: "Return Plugin API",
        description: "Integrate with Ease. Customize with Confidence.",
      },
      copyLoginUrlButtonText: "copy",
      copyLoginBodyButtonText: "copy",
      copyCreateReturnBodyButtonText: "copy",
      copyGetLocationButtonText: "copy",
      copyGetLocationBodyButtonText: "copy",
    };
  },
  methods: {
    copyLoginUrl() {
      const snippet = `/auth/login`;
      navigator.clipboard.writeText(snippet);
      this.copyReqButtonText = "copied";
    },
    copyLoginBody() {
      const snippet = `{email: [YOUR_EMAIL],
password: [YOUR_PASSWORD]
}`;
      navigator.clipboard.writeText(snippet);
      this.copyLoginBodyButtonText = "copied";
    },
    copyRequest() {
      const snippet = `{
  "customer": "STL7VaB9g1",
}`;
      navigator.clipboard.writeText(snippet);
      this.copyReqButtonText = "copied";
    },

    copyCreateReturnBody() {
      const snippet = `{
    "client_reference":"YOUR_TOKEN", 
    "original_client_reference": "YOUR_TOKEN", 
    "original_awb": "AWB", 
    "expected_collection_date": "YYYY-MM-DD HH:MM:SS", 
    "warehouse":"[WAREHOUSE]", 
    "collection_address": {
        "name": "[NAME]", 
        "phone": "[PHONE]", 
        "state": "[STATE]", 
        "city": "[CITY]", 
        "area": "[AREA]", 
        "address": "[ADDRESS]", 
        "address_2": "[ADDRESS]" 
    },
    "items": [
        {
            "category": "[CATEGORY]", 
            "length": [NUM], 
            "width": [NUM], 
            "height": [NUM], 
            "price": [NUM], 
            "weight": [NUM],
            "description":"[DES]"
        }
    ]
}`;
      navigator.clipboard.writeText(snippet);
      this.copyCreateReturnBodyButtonText = "copied";
    },
    copyLocationUrl() {
      const snippet = `/api/v3/returns/locations`;
      navigator.clipboard.writeText(snippet);
      this.copyGetLocationButtonText = "copied";
    },
    copyGetLocationBody() {
      const snippet = `{
"country_code": "KSA",
}`;
      navigator.clipboard.writeText(snippet);
      this.copyGetLocationBodyButtonText = "copied";
    },
  },
};
</script>

<style scoped>
.highlight {
  position: relative;
  background: #1e1e3f;
  border-radius: 0.5rem;
  padding: 1rem;
}

.highlight .highlight-copy {
  position: absolute;
  right: 1.75rem;
  top: 1.5rem;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.35rem 1rem !important;
  transition: all 0.2s ease;
  background-color: #2d2d5e;
  color: rgba(255, 255, 255, 0.75);
}
.highlight .highlight-copy-rtl {
  position: absolute;
  right: 1.75rem;
  top: 1.5rem;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.35rem 1rem !important;
  transition: all 0.2s ease;
  background-color: #2d2d5e;
  color: rgba(255, 255, 255, 0.75);
}

.highlight .highlight-copy:hover {
  transition: all 0.2s ease;
  background-color: #323268;
  color: #009ef7;
}

.highlight span {
  width: 85%;
  color: #a5c261;
  text-align: left;
  word-break: break-all;
  font-family: "Operator Mono", "Fira Code", Consolas, Monaco, "Andale Mono",
    "Ubuntu Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.token-tag {
  color: rgb(255, 157, 0) !important;
}
.token-attr {
  color: rgb(255, 180, 84) !important;
}
.token-value {
  color: #a5c261 !important;
}
.token-string {
  color: #b362ff !important;
}
code {
  color: #b93993 !important;
}

.highlight-code {
  direction: ltr !important;
  text-align: left;
}
</style>
